<template>
  <section>
    <div class="grid md:grid-9-3 ">
      <div class=" h-65vh position-relative" :style="left">
        <transition name="fade" v-for="(addOn,i) in addOns">
          <div
              style="background-size: cover; background-position: center center; overflow: hidden; background-repeat: no-repeat; position: absolute; z-index:1; top:0; "
              :style="{backgroundImage:'url('+orgamaxImage(addOn)+')'}" v-if="last && addOn.id===last.id"
              class="w-100p h-100p position-relative">
          </div>
        </transition>
      </div>
      <div class="m-l-2 p-3 d-flex h-65vh" style="flex-direction: column; overflow-y: auto">
        <selection-header :back-to="'/supply'" :steps="6" :current-step="6"/>
        <total-runner :step="7" class="f-1"/>
        <bottom-nav to="/checkout" back-to="/supply"/>
      </div>
    </div>
    <tab-shop :highlighted="getSelected" selected-tab="add-ons" v-if="addOns.length>0" :multi-items="{'add-ons':addOns}" @update="apply">
      Exclusive offer
    </tab-shop>
  </section>

</template>

<script>
import selectionHeader from "@/components/selectionHeader";
import bg from "@/assets/addOnBg.png";
import api from "@/api";
import totalRunner from "@/components/totalRunner";
import BottomNav from "@/components/BottomNav";
import TabShop from "@/components/TabShop"
import {orgamaxImage} from "@/composables/orgamax";

import bgImg from "@/assets/add-ons.jpg"

export default {
  name: "AddOns",
  components:{selectionHeader, totalRunner, TabShop, BottomNav},
  data:()=>({
    left:{backgroundImage: 'url('+bgImg+')', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'},
    addOns:[],
    active:[],
    selected: null,
    last: null
  }),
 /* watch:{
    selected(newVal){
      if(newVal){
        this.last = newVal;
        this.apply(newVal)
      }
    }
  },*/
  computed:{
    getSelected(){
      return this.$store.state.addOns;
    }
  },
  async mounted() {

    this.addOns = this.$store.getters.addOns;

  },
  methods:{
    orgamaxImage,
    apply(addon){
      this.last = addon.item;
      if(this.$store.state.addOns.find(a => a.id === addon.item.id)){
        this.$store.commit('removeAddOn', addon.item)
      } else {
        this.$store.commit('addAddOn', addon.item)
      }
    }
  }
}
</script>

<style scoped>

</style>